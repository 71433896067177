<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          {{ $t("DAILY_TASKS") + " - " + storeName }}
        </div>
        <div class="card-toolbar">
          <v-progress-circular
            v-show="isPDFDaily"
            indeterminate
            color="green"
          ></v-progress-circular>
          <v-btn
            v-show="!isPDFDaily"
            color="btn-success"
            class="btn btn-light-success font-weight-bolder mr-2 mt-3"
            @click="getStoreDailyTasksPDF(1)"
          >
            <i class="flaticon-download"></i>
            {{ $t("DOWNLOAD_PDF_DAILY_TASKS") }}
          </v-btn>
          <v-dialog
            :retain-focus="false"
            v-model="dialogPDFHygiene"
            max-width="900px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-progress-circular
                v-show="isPDFHygiene"
                indeterminate
                color="green"
              ></v-progress-circular>
              <v-btn
                v-show="!isPDFHygiene"
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon-download"></i>
                {{ $t("DOWNLOAD_PDF_HYGIENE") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{
                  $t("DOWNLOAD_PDF_HYGIENE") + " - " + storeName
                }}</span>
              </v-card-title>

              <v-card-text>
                <b-col>
                  <FormDatepicker
                    v-model="dates"
                    :type="'text'"
                    :clearable="true"
                    :i18n="{
                      label: 'DATE'
                    }"
                  ></FormDatepicker>
                </b-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getStoreDailyTasksPDF(2)"
                >
                  Генерирай
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            :retain-focus="false"
            v-model="dialogPDFUtensils"
            max-width="900px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-progress-circular
                v-show="isPDFUtensils"
                indeterminate
                color="green"
              ></v-progress-circular>
              <v-btn
                v-show="!isPDFUtensils"
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon-download"></i>
                {{ $t("DOWNLOAD_PDF_DISINFECTION_UTENSILS") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{
                  $t("DOWNLOAD_PDF_DISINFECTION_UTENSILS") + " - " + storeName
                }}</span>
              </v-card-title>

              <v-card-text>
                <b-col>
                  <date-picker
                    v-model="weekNumber"
                    valueType="date"
                    type="week"
                  ></date-picker>
                  <div
                    class="mt-3 font-size-lg font-weight-bold"
                    v-show="weekNumber"
                  >
                    {{ formattedWeekNumber(weekNumber) }}
                  </div>
                </b-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getStoreDailyTasksPDF(3)"
                >
                  Генерирай
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            :retain-focus="false"
            v-model="dialogPDFToilets"
            max-width="900px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-progress-circular
                v-show="isPDFToilets"
                indeterminate
                color="green"
              ></v-progress-circular>
              <v-btn
                v-show="!isPDFToilets"
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon-download"></i>
                {{ $t("DOWNLOAD_PDF_DISINFECTION_TOILETS") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{
                  $t("DOWNLOAD_PDF_DISINFECTION_TOILETS") + " - " + storeName
                }}</span>
              </v-card-title>

              <v-card-text>
                <b-col>
                  <date-picker
                    v-model="weekNumber"
                    valueType="date"
                    type="week"
                  ></date-picker>
                  <div
                    class="mt-3 font-size-lg font-weight-bold"
                    v-show="weekNumber"
                  >
                    {{ formattedWeekNumber(weekNumber) }}
                  </div>
                </b-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="getStoreDailyTasksPDF(4)"
                >
                  Генерирай
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :sort-desc="false"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingStoreDailyTasks"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="kitchen-equipments-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
            >
              <template v-slot:prepend>
                <b-col>
                  <v-autocomplete
                    hide-details
                    v-if="_.size(stores) > 0"
                    :items="stores"
                    :label="$t('STORE')"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-model="filters.storeId"
                  ></v-autocomplete>
                </b-col>
                <b-col>
                  <FormDatepickerBase
                    :type="'text'"
                    :clearable="true"
                    :i18n="{
                      label: 'DATE'
                    }"
                    :date.sync="filters.date"
                  ></FormDatepickerBase>
                </b-col>
              </template>
            </SearchTools>
          </template>

          <template v-slot:item.taskName="{ item }">
            {{ item.taskName }}
          </template>

          <template v-slot:item.storeName="{ item }">
            {{ item.storeName }}
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | formatDateTime }}
          </template>
          <template v-slot:item.comment="{ item }">
            {{ item.comment }}
          </template>
          <template v-slot:item.isCompleted="{ item }">
            <v-chip :color="getColor(item.isCompleted)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.isCompleted) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.isCompleted) }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-dialog
              v-model="dialogEdit"
              max-width="600px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="!item.isCompleted"
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  x-small
                  @click="edit(item)"
                >
                  <v-icon color="green"
                    >mdi-checkbox-marked-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{
                    $t("DAILY_TASK") + " - " + storeName
                  }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="form.comment"
                          :label="$t('COMMENT')"
                          hide-details
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="update()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<script>
import {
  FETCH_STORE_DAILY_TASKS,
  UPDATE_STORE_DAILY_TASK,
  GET_STORE_DAILY_TASKS_PDF
} from "@/modules/daily-tasks/store/daily-tasks.module";
//Fetch Fridges
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import moment from "moment";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/bg";

export default {
  name: "DailyTasksByStore",
  mixins: [permissionMixin, formBuilderMixin],
  data() {
    return {
      weekNumber: null,
      isPageLoading: true,
      isPDFDaily: false,
      isPDFHygiene: false,
      isPDFUtensils: false,
      isPDFToilets: false,
      tableLoading: true,
      firstLoader: true,
      search: "",
      dialogEdit: false,
      dialogPDFHygiene: false,
      dialogPDFUtensils: false,
      dialogPDFToilets: false,
      items: [],
      stores: [],
      dates: [],
      totalItems: 500,
      searchQuery: "",
      storeName: "",
      form: {
        comment: ""
      },
      submitForm: "",
      filters: {
        name: "",
        storeId: null,
        date: null,
        disinfectionDate: null
      },
      options: {
        descending: false,
        page: 1,
        itemsPerPage: 50,
        totalItems: 500
      },
      rowsPerPageItems: [50, 100, 150, 200, 250],
      columns: {},
      headers: [
        { text: "Name", value: "taskName", sortable: false },
        { text: "Store", value: "storeName", sortable: false },
        { text: "Created", value: "createdAt", sortable: false },
        { text: "Comment", value: "comment", sortable: false },
        {
          align: "right",
          value: "isCompleted",
          sortable: false
        },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px"
        }
      ]
    };
  },
  components: {
    FormDatepicker,
    FormDatepickerBase,
    SearchTools,
    DatePicker
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stores Daily Tasks", route: { name: "store-daily-tasks" } },
      { title: "Stores Daily Tasks List" }
    ]);
    vm.getSelectsStores();
  },
  computed: {
    ...mapGetters(["getStoreDailyTasks", "isLoadingStoreDailyTasks"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        storeId: this.filters.storeId,
        date: this.filters.date,
        disinfectionDate: this.filters.disinfectionDate
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialogPDFHygiene(val) {
      val || this.close();
    },
    dialogPDFUtensils(val) {
      val || this.close();
    },
    dialogPDFToilets(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.close();
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.dialogEdit = false;
      vm.dialogPDFHygiene = false;
      vm.dialogPDFUtensils = false;
      vm.dialogPDFToilets = false;
    },
    edit(item) {
      this.submitForm = Object.assign({}, item);
      this.dialogEdit = true;
    },
    update() {
      let vm = this;
      let id = vm.submitForm.id;
      let payload = {
        comment: vm.form.comment,
        isCompleted: 1
      };

      this.$store
        .dispatch(UPDATE_STORE_DAILY_TASK, { id: id, payload: payload })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.form.comment = "";
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.storeName = vm.stores[0].name;
      vm.searchQuery = "";
      vm.filters.storeId = "";
      vm.filters.date = "";
      vm.filters.disinfectionDate = "";
      vm.dates = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      vm.isLoadingStoreDailyTasks = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORE_DAILY_TASKS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            vm.fetchStoreName();
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    formattedWeekNumber(weekNumber) {
      let selectedWeek = new moment(weekNumber);
      let startWeekDate = selectedWeek.startOf("isoWeek").format("DD-MM-YYYY");
      let endWeekDate = selectedWeek.endOf("isoWeek").format("DD-MM-YYYY");
      return startWeekDate + " ~ " + endWeekDate;
    },
    getStoreDailyTasksPDF(pdfTypeId) {
      let vm = this;
      if (pdfTypeId == 1) {
        vm.isPDFDaily = true;
      }
      if (pdfTypeId == 2) {
        vm.isPDFHygiene = true;
      }
      if (pdfTypeId == 3) {
        vm.isPDFUtensils = true;
      }
      if (pdfTypeId == 4) {
        vm.isPDFToilets = true;
      }
      let selectedWeek = new moment(vm.weekNumber);
      let startWeekDate = selectedWeek.startOf("isoWeek").format("YYYY-MM-DD");
      let endWeekDate = selectedWeek.endOf("isoWeek").format("YYYY-MM-DD");
      let weekDays = [startWeekDate, endWeekDate];
      //copy current params to modify
      vm.isLoadingStoreDailyTasks = true;
      let datePDF =
        vm.filters.date != null || vm.filters.date != ""
          ? vm.filters.date
          : moment().format("MM-DD-YYYY");
      if (datePDF == null) {
        datePDF = moment().format("MM-DD-YYYY");
      }
      let params = this.params;
      if (pdfTypeId === 2) {
        datePDF = vm.dates;
        params.dateRange = vm.dates;
        params.weekDays = null;
      }
      if (pdfTypeId === 3 || pdfTypeId === 4) {
        datePDF = weekDays;
        params.weekDays = weekDays;
        params.dateRange = null;
      }
      params.pdfTypeId = pdfTypeId;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(GET_STORE_DAILY_TASKS_PDF, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "store-tasks-" + vm.storeName + "-" + datePDF;
            link.click();
            link.remove();
            vm.dialogPDFHygiene = false;
            vm.dialogPDFUtensils = false;
            vm.dialogPDFToilets = false;
            if (
              vm.isPageLoading == true ||
              vm.isPDFDaily == true ||
              vm.isPDFHygiene == true ||
              vm.isPDFUtensils == true ||
              vm.isPDFToilets == true
            ) {
              vm.isPageLoading = false;
              if (pdfTypeId == 1) {
                vm.isPDFDaily = false;
              }
              if (pdfTypeId == 2) {
                vm.isPDFHygiene = false;
              }
              if (pdfTypeId == 3) {
                vm.isPDFUtensils = false;
              }
              if (pdfTypeId == 4) {
                vm.isPDFToilets = false;
              }
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getSelectsStores() {
      let vm = this;
      let payload = {
        stores: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stores = data.data.stores;
          vm.filters.storeId = data.data.stores[0].id;
          vm.fetchStoreName();
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "primary";
      else return "green";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "in progress";
      else return "completed";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-alarm";
      else return "mdi-checkbox-marked-circle-outline";
    },
    fetchStoreName() {
      let vm = this;
      if (vm.filters.storeId == "" || vm.filters.storeId == null) {
        if (vm.stores.length != 0) {
          vm.storeName = vm.stores[0].name;
        }
      } else {
        vm.storeName = vm.stores.find(function(store) {
          return store.id === vm.filters.storeId;
        }).name;
      }
    }
  }
};
</script>
